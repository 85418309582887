import React from 'react'
import { Animated } from "react-animated-css";
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

function Portfolio() {
  return (
    <div className="md:m-0 my-16">
      <div className="grid md:grid-cols-11 gap-1 md:mb-36 mb-14">
        <div className="md:col-span-3">
          <Navbar />
        </div>
        <div className="md:col-span-5 md:mt-32 md:px-24 px-5">
          <Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={true}>
            <div className='text-light-grey md:text-2xl text-xl mt-5'>
              <ul className='list-disc px-6'>
                <li>Personal Website</li>
                <li>Personal Website v2 (React)</li>
                <li>{'Matrix Teknoloji -> Front-End Developer'}</li>
                <li>{"Demirtaş Sistem -> Full Stack Developer"}</li>
              </ul>
              <div className='mt-3'>
                <span className='text-light-blue'> cd /github</span> go to github or <a href='https://github.com/mrfurkanaydin' className='text-light-blue' target="_blank" download="Furkan Aydın CV.pdf" rel="noopener noreferrer">click here</a>.
              </div>
            </div>
          </Animated>
        </div>
      </div>
      <Footer />

    </div>
  )
}

export default Portfolio