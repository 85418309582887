import './App.css';
import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Home from './components/Home/Home'
import About from './components/About/About';
import Portfolio from './components/Portfolio/Portfolio'
import Other from './components/Other/Other'
import Social from './components/Social/Social';
import Nasa from './components/Nasa/Nasa';
import Error from './components/404/Error'
import Ipaddress from './components/Ipaddress/Ipaddress';
import Irem from './components/Irem/Irem';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/portfolio" element={<Portfolio />} />
        <Route exact path="/other" element={<Other />} />
        <Route exact path="/github" element={<Social />} />
        <Route exact path="/instagram" element={<Social />} />
        <Route exact path="/linkedin" element={<Social />} />
        <Route exact path='/other/nasa' element={<Nasa />} />
        <Route exact path="/other/ipaddress" element={<Ipaddress />} />
        <Route exact path="/iremim" element={<Irem />} />
        <Route exact path='*' element={< Error />} />
      </Routes>
    </Router>
  );
}

export default App;
