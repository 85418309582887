import React from 'react'
import { Animated } from "react-animated-css";
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import nasaLogo from '../../assets/img/nasa-logo.png';
import ipLogo from '../../assets/img/ip-logo.png';

function Other() {
  return (
    <div className="md:m-0 my-16">
      <div className="grid md:grid-cols-11 gap-1 md:mb-5 mb-10">
        <div className="md:col-span-3">
          <Navbar />
        </div>
        <div className="md:col-span-5 md:mt-10 px-5">
          <Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={true}>
            <div className='text-light-grey md:text-2xl text-3xl mt-5'>
              <div className='grid md:grid-cols-2'>
                <div>
                  <Link to='/other/nasa'>
                    <div className='md:h-64 md:w-64 mb-5 bg-white rounded'>
                      <img src={nasaLogo} alt='nasa' className='object-contain h-full' />
                      <div className='text-center'>Nasa APOD</div>
                    </div>
                  </Link>

                </div>
                <div>
                  <div className='md:h-64 md:w-64 mb-5 bg-white rounded'>
                    <Link to='/other/ipaddress'>
                      <div className='md:h-64 md:w-64 md:mb-5 bg-white rounded'>
                        <img src={ipLogo} alt='ipaddress' className='object-contain h-full' />
                        <div className='text-center'>Public IP Address</div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Animated>
        </div>
      </div>
      <Footer />

    </div>
  )
}

export default Other