import React from 'react'
import { Animated } from "react-animated-css";
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

function Error() {
  return (
    <div className="md:m-0 my-16">
      <div className="grid md:grid-cols-11 gap-1 md:mb-5 mb-10">
        <div className="md:col-span-3">
          <Navbar />
        </div>
        <div className="md:col-span-5 md:mt-10 px-5">
          <Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={true}>

            <div className='text-light-blue md:text-9xl text-center text-3xl mt-5'>
              404 
            </div>
            <div className='text-white md:text-5xl text-3xl text-center'>
              Page not found
            </div>
          </Animated>
        </div>
      </div>
      <Footer />

    </div>
  )
}

export default Error