import React, { useState } from 'react'
import { Animated } from "react-animated-css";
import Typed from 'react-typed';
import cv from '../../assets/file/Furkan Aydın CV.pdf'


function Footer() {
    const [path, setPath] = useState(window.location.pathname)
    const [command, setCommand] = useState('')
    const [value, setValue] = useState('')
    const handleChange = (e) => {
        setValue(e.target.value.toLowerCase())
        const sentence = e.target.value.toLowerCase()
        setCommand(sentence.split(' ')[0])
        setPath(sentence.split(' ')[1])
    }
    const handlePress = (e) => {
        if (command === 'cd') {
            if (e.keyCode === 13) {
                window.location.href = path
                clear()
            }
        }
        if (command === 'clone') {
            if (e.keyCode === 13) {
                window.open(cv)
                clear()
            }
        }
    }
    const clear = () => {
        setCommand('')
        setPath('')
        setValue('')
    }
    return (<>

        <div className="grid md:grid-cols-11">
            <div className="md:col-span-3"></div>
            <div className="md:col-span-5 md:px-24 px-3 text-black">
                <Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={true}>
                    <Typed className='text-black'
                        strings={[
                            'write something to go',
                            'cd /home',
                            'cd /about',
                            'clone /cv',
                            'cd /portfolio',
                            'cd /github',
                            'cd /other',
                            'cd /instagram',
                            'cd /linkedin',
                        ]}
                        typeSpeed={40}
                        backSpeed={50}
                        attr="placeholder"
                        loop >
                        <input value={value} onChange={handleChange} onKeyDown={handlePress} type="text" className='bg-light-blue rounded w-full h-12 text-xl px-3 text-black placeholder:text-black focus:outline-none' />
                    </Typed>
                </Animated>
                <div>
                    <div className='text-white text-3xl text-center mt-16 '>created by <span className='text-light-blue'>furkan aydin</span></div>
                    <div className='text-white text-center'>Copyright <span className="text-light-blue">{new Date().getFullYear()}</span>. All rights reserved. ©</div>
                </div>
            </div>
            <div className='md:col-span-3  md:p-2 md:px-20 mx-auto'>
            </div>


        </div>
        <div className='text-center mx-auto'>
            <a className='mr-3' href='mailto:aydnfurkan@gmail.com' target='_blank' rel="noopener noreferrer"><i className="fa-solid fa-envelope"></i></a><a className='mr-3' href='https://instagram.com/mrfurkanaydin' target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-instagram"></i></a><a className='mr-3' href='https://github.com/mrfurkanaydin' target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-github"></i></a><a className='mr-3' href='https://www.linkedin.com/in/mrfurkanaydin/' target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-linkedin"></i></a>
        </div>
    </>
    )
}

export default Footer