import React, { useEffect } from 'react'

function Github() {

    useEffect( ()=>{
        if(window.location.pathname === '/github'){
            window.location.href = 'https://github.com/mrfurkanaydin'
        }
        if(window.location.pathname === '/instagram'){
            window.location.href = 'https://www.instagram.com/mrfurkanaydin'
        }
        if(window.location.pathname === '/linkedin'){
            window.location.href = 'https://www.linkedin.com/in/mrfurkanaydin'
        }
    },[])

    return (
        <div>
            {window.location.pathname === '/github' && 'Redirecting to My Github Page...' }
            {window.location.pathname === '/instagram' && 'Redirecting to My Instagram Page...' }
            {window.location.pathname === '/linkedin' && 'Redirecting to My Linkedin Page...' }
            
        </div>
    )
}

export default Github