import React from 'react'
import { Animated } from "react-animated-css";
import Navbar from '../Navbar/Navbar';
import profile from '../../assets/img/profile.jpg'
import cv from '../../assets/file/Furkan Aydın CV.pdf'
import Footer from '../Footer/Footer';

function About() {
    return (
        <div className="md:m-0 my-16">
            <div className="grid md:grid-cols-11 gap-1 md:mb-5 mb-10">
                <div className="md:col-span-3">
                    <Navbar />
                </div>
                <div className="md:col-span-5 md:mt-10 px-5">
                    <Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={true}>
                    <img src={profile} alt='profile' className='w-60 h-60 rounded-full mx-auto' />
                        <div className='text-light-grey md:text-2xl text-xl mt-5'>
                            I am 4th year software engineering student
                            at <a href="https://www.beykent.edu.tr/" target="_blank" rel="noopener noreferrer" className='text-light-blue'>Beykent University</a>.
                            Here are some of the technologies I use:
                            <div className='grid grid-cols-2 px-6'>
                                <div>
                                    <ul className='list-disc'>
                                        <li>Html-Css</li>
                                        <li>Javascript</li>
                                        <li>Node.js</li>
                                    </ul>
                                </div>
                                <div>
                                    <ul className='list-disc'>
                                        <li>React.js</li>
                                        <li>Python</li>
                                        <li>Java</li>
                                    </ul>
                                </div>
                            </div>
                            <span className='text-light-blue'> clone /cv</span> download my cv or <a href={cv} className='text-light-blue' target="_blank" download="Furkan Aydın CV.pdf" rel="noopener noreferrer">click here</a>.
                        </div>
                    </Animated>
                </div>
            </div>
            <Footer />

        </div>
    )
}

export default About