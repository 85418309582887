import React, { useEffect,useState } from 'react'
import { Animated } from "react-animated-css";
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import axios from 'axios';

function Ipaddress() {
    const [data, setData] = useState()
    console.log(data);
    useEffect(()=>{
        axios.get('https://ipinfo.io/json?token=7f0a143601e2da').then(
            res =>  setData(res?.data)
        )
    },[])
    return (
        <div className="md:m-0 my-16">
            <div className="grid md:grid-cols-11 gap-1 md:mb-16 mb-10">
                <div className="md:col-span-3">
                    <Navbar />
                </div>
                <div className="md:col-span-5 md:mt-10 px-5">
                    <Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={true} className='text-center'>
                    <div className='text-light-grey md:text-3xl text-3xl mt-10'>
                            Your Public Ip Address
                        </div>
                        <div className='text-light-blue md:text-7xl text-5xl mt-5'>
                            {data?.ip}
                        </div>
                        <div className='text-light-grey md:text-2xl text-3xl mt-5'>
                            {data?.timezone}
                        </div>
                        <div className='text-light-grey md:text-xl text-xl mt-5'>
                            (Turn off Ad Blocker if it doesn't appear)
                        </div>
                    </Animated>
                </div>
            </div>
            <Footer />

        </div>
    )
}

export default Ipaddress