import React from 'react'
import { Animated } from "react-animated-css";
import Typed from 'react-typed';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';


function Home() {
    return (
        <div className="md:m-0 my-16">
            <div className="grid md:grid-cols-11 gap-1 md:mb-24 mb-10">
                <div className="md:col-span-3">
                    <Navbar />
                </div>
                <div className="md:col-span-5 md:mt-36 px-5">
                    <Typed className="md:text-8xl text-5xl"
                        strings={[`hi, I am  <span class="text-light-blue">furkan.</span>`]}
                        typeSpeed={80}
                    />
                    <Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={true}>
                        <div className='text-light-grey md:text-5xl text-3xl'>I’m a software engineer student.
                            I’m interested in making website, mobile app
                            and ai.</div>
                    </Animated>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home
