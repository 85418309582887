import React, { useEffect } from 'react'
import { Animated } from "react-animated-css";
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import axios from 'axios';

function Nasa() {
    const [data, setData] = React.useState()
    console.log(data);
    useEffect(() => {
        function removeDays(theDate, days) {
            return new Date(theDate.getTime() - days * 24 * 60 * 60 * 1000);
        }
        var date = removeDays(new Date(), 14);
        var day = date.getDate();
        var year = date.getFullYear();
        var month = date.getMonth() + 1 //Month are zero based 
        var start_date = `${year}-${month}-${day}`
        axios.get(`https://api.nasa.gov/planetary/apod?start_date=${start_date}&api_key=KnDRcenOnEToeMi3MXWiqeCHmfF1Jqzidr44HwNj`).then(
            res => setData(res.data)
        )
    }, [])
    return (
        <div className="md:m-0 my-16">
            <div className="grid md:grid-cols-11 gap-1 md:mb-36 mb-14">
                <div className="md:col-span-3">
                    <Navbar />
                </div>
                <div className="md:col-span-5 md:mt-32 md:px-24 px-5">
                    <Animated animationIn="fadeInUp" animationOut="fadeOut" isVisible={true}>
                        <div className='text-light-grey md:text-2xl text-3xl mt-5'>
                            <div className='grid md:grid-cols-2 gap-10'>
                                {data?.map(element => {
                                    return (
                                        <div className="flex">
                                            <a href={element.hdurl} target="_blank" rel="noopener noreferrer">
                                                <div className='md:h-64 md:w-64 md:my-5  align-center justify-center text-center rounded'>
                                                    <img className='object-contain' src={element.url} alt={element.title} />
                                                </div>
                                            </a>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </Animated>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Nasa