import React, { useRef } from "react";
import music from "./sarki.mp3";
function Irem() {
  const audioRef = useRef(null);

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };
  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };
  return (
    <div className="flex flex-col justify-center items-center mt-12">
      {/* <button onClick={playAudio}>Sana özel sesi çal</button>
      <button onClick={pauseAudio}>Durdur</button>
      <audio ref={audioRef} autoPlay>
        <source src={music} type="audio/mpeg" />
      </audio> */}
      <div className="mx-10 mt-10 flex flex-col justify-center items-center text-center">
        <span className="text-5xl text-red-600">Seni Seviyorum</span>
        <span className="text-5xl text-red-600">❤︎</span>
        <div className="font-sans text-xl text-red-600">
          İremim Cennet Bahçem Güzel Kokulum
        </div>
        <div className="font-sans text-xl">
          Senin için ne ifade eder bilmiyorum ama benim için bir ilk bu ve gayet
          iyi hissediyorum. Senle olmak senle yaşamak senle gezmek tozmak senle
          büyümek benim için inanılmaz özel şeyler ve yine böylece anılar
          biriktirmeye devam ediyoruz. Her anımız farklı bi tecrübe her anımız
          farklı bir heyecan her anımız farklı bir tutku. Bu günlerimiz ayrı
          geçerken özlemine dayanmak çok zor geliyor bitanem. Şuan sadece bu
          zamanın neticesine odaklandım. çünkü biliyorum ki sonu çok güzel
          olacak. Sen varsan benim için hep güzel oldu zaten. Şuan bu cümleleri
          sana bakarak yazıyorum ama sen bunun pek farkında değilsin.
          güzelliğinin de farkında değilsin. ben sana deli gibi aşıkken
          gözlerinin içinde kayboluyorum. hayatımızdan 9 ay beraber geçti
          beraber gezdik beraber eğlendik. hep anladıkki birlikte keyif alıyoruz
          ve eğleniyoruz. bizi biz yapan ortak noktlardan biri de bu. ben seni
          çok seviyorum hayatımmm. Hayatıma değer katıyosun. Şimdi 9.ay hala ilk
          günkü kadar çok seviyorum. Senle yaptığım her şey bana kattığın farklı
          şeyler oluyor. Özlem çok zor ama aynı havayı solumamız bile güzel.
          bazılarının bunda bile şansı yok. Belde-i tayyibe'deyiz. Bu bizim için
          büyük bir şans. Her türlü imkan burada. Güzel günler uzak değil günler
          hızlı geçiyor. Daha da hızlı geçicek. Bizde vuslata ereceğiz. 9 aydır
          bana katlandığın ve beni sevdiğin için sana teşekkür ediyorum. Bazı
          kötü zamanlar geçiriyoruz ama bence sevgi her şeyi çözmeli. Ben seni
          ilk günkü heyecanla o midpointten çıkıp konuştuğumuz heyecanla o ilk
          starbucksa gidip konuştuğumuz aşkla ve o beraber ağlayıp beraber
          güldüğümüz beraber saçmaladığımız o malum günkü kadar yani seni hala
          çok seviyorum sevgilim. Sevdiceğim. 9.ayımız kutlu olsun. Nice Aylara
          Nice Yıllara Sevgilim.
        </div>

        <div className="flex mx-auto gap-2 mt-5 justify-between">
          <img src="/10.jpg" alt="resim" className="w-5/12 h-auto" />
          <img src="/18.jpg" alt="resim" className="w-5/12 h-auto" />
        </div>
        <div className="flex mx-auto gap-2 mt-5 justify-between">
          <img src="/12.jpg" alt="resim" className="w-5/12 h-auto" />
          <img src="/13.jpg" alt="resim" className="w-5/12 h-auto" />
        </div>
        <div className="flex items-center justify-center mt-5">
          <img src="/14.jpg" alt="resim" className="w-5/12 h-auto" />
        </div>
      </div>
    </div>
  );
}

export default Irem;
