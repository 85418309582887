import React from 'react'
import { Link } from 'react-router-dom'

function Navbar() {
    const pathname = window.location.pathname

    
    return (<>
        <div className='md:grid grid-cols-7 hidden'>
            <div className='col-span-2'></div>
            <div className='col-span-5 mt-32 text-light-blue text-3xl'>
                {`$root -> ${pathname}`}
                <div>
                    <Link to='/' className='hover:text-light-grey'><span className="material-symbols-outlined">
                        subdirectory_arrow_right
                    </span>/home</Link>
                </div>
                <div>
                    <Link to='/about' className='hover:text-light-grey'><span className="material-symbols-outlined">
                        subdirectory_arrow_right
                    </span>/about</Link>
                </div>
                <div>
                    <Link to='/portfolio' className='hover:text-light-grey'><span className="material-symbols-outlined">
                        subdirectory_arrow_right
                    </span>/portfolio</Link>
                </div>
                <div>
                    <Link to='/other' className='hover:text-light-grey'><span className="material-symbols-outlined">
                        subdirectory_arrow_right
                    </span>/other</Link>
                </div>
            </div>
        </div>
        <div className='md:hidden text-light-blue text-xl text-center mb-10'>
            <div>{`$root -> ${pathname}`}</div>
            <Link to='/' className='hover:text-light-grey ml-3'>/home</Link>
            <Link to='/about' className='hover:text-light-grey ml-3'>/about</Link>
            <Link  to='/portfolio' className='hover:text-light-grey ml-3'>/portfolio</Link>
            <Link  to='/other' className='hover:text-light-grey ml-3'>/other</Link>
        </div>
    </>
    )
}

export default Navbar